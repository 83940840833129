<template>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">Liste des projets</div>
              <nav aria-label="breadcrumb" class="float-right">
                <ol class="breadcrumb">
                  <router-link to="/addannonce">
                    <li class="breadcrumb-item btn btn-primary">
                      Affecter un projet
                    </li>
                  </router-link>
                </ol>
              </nav>
              <div class="table-responsive">
                <input
                  value=""
                  class="form-control search-input"
                  placeholder="Recherche par nom "
                  type="text"
                  v-model="search"
                />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Nom </th>
                      <th scope="col">Type </th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Animatrice</th>
                      <th scope="col">Client</th>
                      <th scope="col">Délégué</th>
                      <th scope="col">Date </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(annonce, index) in searchFunction" :key="index">
                      <td>{{ annonce.nom }} </td>
                      <td><b-badge :variant="light-success">
                        {{ annonce.type }}
                          </b-badge></td>
                      <!-- <td>{{ annonce.type }} </td> -->
                      <td v-if="annonce.type == 'Projet'"> 
                        - 
                       </td>
                       <td v-else> 
                        {{ annonce.quantite }}
                       </td>
                      <td>
                        <b>{{ annonce.animatrice_id["name"] }} </b>
                      </td>
                      <!-- <td>
                      <li v-for="(x, key1) in annonce.produit_id" :key="key1">
                        {{ x.reference }}
                      </li> 
                    </td> -->
                    <!-- <td>{{ annonce.produit_id }}</td>  -->
                      <td><b>{{ annonce.client_id["name"] }}</b></td>
                      <td>
                        <b>{{ annonce.delegue_id["name"]}} </b>
                      </td>
                      <td>{{ annonce.date | formatDate }}</td>

                       <td  >
                      <router-link 
                        :to="{ name: 'updateannonce', params: { id: annonce._id } }"
                        ><i 
                        class="mdi mdi-pen icone text-success" style="color:green"></i></router-link
                      >&nbsp;
                      <i 
                        class="mdi mdi-delete icone text-danger"  style="color:red"
                         @click="deleteannonce(annonce._id)"
                      />
                    </td> 
                    
                    </tr>
                  </tbody> 
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from "@/axios";
  import Swal from "sweetalert2";
  import Toast from "sweetalert2";
  import moment from 'moment'

  export default {
    name: "basicTables",
    data() {
      return {
        annonces: [],
        search: "",
      };
    },
  
    created() {
      this.getannonces();
    },
    computed: {
      searchFunction() {
        return this.annonces.filter((item) => {
          return item.nom.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
      },
    },
    methods: {
      getannonces() {
        HTTP.get("annonces/getannonces")
          .then((response) => {
            this.annonces = response.data;
            console.log(this.annonces);
          })
          .then((err) => console.log(err));
      },
      
      deleteannonce(id) {
        Swal.fire({
          title: "Êtes-vous sûrs ?",
          text: "Vous voulez supprimer le projet",
          icon: "warning",
          cancelButtonText: "Annuler",
          showCancelButton: true,
          confirmButtonColor: "#3e884f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, valider!",
        }).then((result) => {
          if (result.isConfirmed) {
          HTTP
              .delete("annonces/deleteAnnonce/" + id)
              .then((response) => {
  
                Toast.fire({
                  position: "top-center",
                  icon: "success",
                  title: "annonce supprimé",
                });
  
                this.getannonces();
              });
          }
        });
      },
    },
  };
  </script>